import React from 'react';
import classNames from 'classnames';
// material-ui components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody.jsx';
import Button from '../CustomButtons/Button.jsx';

import imagesStyles from '../../assets/jss/pale-legs-style/imagesStyles.jsx';

import { cardTitle } from '../../assets/jss/pale-legs-styles';
import FormatDate from '../../Functions/FormateDate';
import isMobile from '../../Functions/isMobile';

const styles = {
    ...imagesStyles,
    cardTitle,
    card: {
        height: isMobile() ? null : 400,
        width: 'inherit',
        borderTopLeftRadius: 25,
        boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px',
        transition: 'all .3s ease-in-out',
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.22) 0px 19px 43px',
            transform: 'translate3d(0px, -5px, 0px)',
        },
    },
    Footer: {
        bottom: 0,
    },
    splitDiv: {
        marginTop: -10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    cardBodyStyle: {
        padding: '0.05rem 1rem',
        flex: '1 1 auto',
    },

};

const useStyles = makeStyles(styles);

export default (props) => {
    const {
        title, image, onClick, date,
    } = props;

    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <img
                className={classNames(classes.blogImage)}
                src={image}
                alt={`blog-image${title}`}
            />
            <CardBody className={classes.cardBodyStyle}>
                <h4><strong>{title.length > 24 ? `${title.substring(0, 24)}...` : title}</strong></h4>
                <div className={classes.splitDiv}>
                    <p style={{ fontSize: 12, color: '#3C4858' }}>{FormatDate(date)}</p>
                    <Button onClick={onClick} size="sm" round>Read More</Button>
                </div>
            </CardBody>
        </Card>
    );
};
