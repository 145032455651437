import React,{useState} from 'react';
import {graphql,Link, navigate} from "gatsby"
import {Breadcrumbs} from "@material-ui/core"


// components
import GridContainer from "../components/Grid/GridContainer"
import { CategoriesBar } from "../components/CategoriesBar"
import BlogCard from "../components/BlogCard/BlogCard"
import GridItem from "../components/Grid/GridItem"


const checkDate = Date.now();
const BlogsPosts=(props)=>{
  const [activeCat,setActiveCat]=useState('All');

  const {_0,_1,_2}=props.data.allSiteData.edges[0].node.content

  const renderBlogs=()=>{
    const blogs = props.data.allBlogs.edges;

    const posts = blogs.reduce((a,v)=>{
      const {categories, publish,date}=v.node;

      if(publish && date<checkDate){
        if(activeCat==='All'){
          a.push(v.node)
        }else if(categories.includes(activeCat)){
          a.push(v.node);
        }
      }
      return a;
    },[])


    return (
      <GridContainer>
        {posts.map(blog=>{
          const {title,id,images,date}=blog;
          return (
            <GridItem key={id} xs={12} sm={12} md={4} lg={3} container justify={'center'}>
              <BlogCard
                title={title}
                image={images[0]}
                date={date}
                onClick={()=>navigate(`/blog/posts/${id}`)}
              />
            </GridItem>
          )
        })}
      </GridContainer>
    )
  }

  return (
    <div style={{marginTop:100,marginLeft:15,marginRight:15}}>
        <Breadcrumbs>
          <Link to={'/'} color={'inherit'}>Home</Link>
          <Link to={'/blog'}>Blog Posts</Link>
        </Breadcrumbs>
        <GridContainer justify={'center'}>
          <GridContainer alignItems={'center'} justify={'center'} direction={'column'}>
            <h2 style={{textAlign:'center'}}>What do you feel like?</h2>
            <CategoriesBar
               active={activeCat}
               categories={['All',_0,_1,_2]}
               handleChange={value=>setActiveCat(value)}
            />
          </GridContainer>
        </GridContainer>
      {renderBlogs()}
    </div>
  )
}


export const query=graphql`
    query BlogPostsQuery {
        allBlogs {
            edges {
                node {
                    id
                    title
                    images
                    categories
                    date
                    publish
                }
            }
        }
        allSiteData(filter: {title: {eq: "categories"}}) {
            edges {
                node {
                    id
                    content {
                        _0
                        _1
                        _2
                    }
                }
            }
        }
    }

`



export default BlogsPosts;