const FormatDate=(date)=>{
  const d = new Date(date);
  const day=d.getDate();
  const month=d.getMonth()+1;
  const year = d.getFullYear();
  const time=d.getHours()+':'+('0'+d.getMinutes()).slice(-2);

  return day+'/'+month+'/'+year;
};

export default FormatDate;